import React from 'react';
import './App.css';
import MapComponent from './MapComponent';
import EventMapComponent from './EventMapComponent';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Local Events and News Map</h1>
      </header>
      <div className="App-intro">
        <p>Click on a marker to see the latest news and events in that area.</p>
      </div>
      <EventMapComponent />
    </div>
  );
}

export default App;