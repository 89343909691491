import React, { useState } from 'react';
import { Marker, Map } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Popup } from 'react-map-gl';



const MapComponent: React.FC = () => {
  const [mapNodes, setMapNodes] = useState<any[]>([]);
  //const [selectedEvent, setSelectedEvent] = useState<any>(null);

  // Read the API url from the environment variable
  const apiUrl = process.env.REACT_APP_API_URL
  // Fetch events data on component mount
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/events/map?upcoming_within=P30D`)
      //fetch('https://baystream-service-6izrvtey5q-uw.a.run.app/api/events/')
      .then((response) => response.json())
      .then((data) => setMapNodes(data));
  }, []);

  return (
    <>
      <Map
        // Read token from environment variable
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: -122.441321,
          latitude: 37.768732,
          zoom: 12
        }}
        style={{ width: "100vw", height: "100vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
      >
        {mapNodes.map((node) => (
          <Marker
            key={node.name}
            latitude={node.location.lat}
            longitude={node.location.lon}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ position: 'relative' }}>
                <img
                  src={`/${node.clusters.length > 0 ? node.clusters[0].icon : 'default_icon.svg'}`}
                  alt="Event Icon"
                  style={{ width: '50px', height: '50px' }}
                />
                <div style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  backgroundColor: 'lightgreen',
                  color: 'black',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  border: '2px solid white', // Adjust color to match the background or preference
                  transform: 'translate(-50%, 50%)', // Center the circle over the top-right corner
                }}>
                  {node.clusters.length > 0 ? node.clusters[0].event_count : ''}
                </div>
              </div>
              <div style={{ marginLeft: '5px', fontSize: '16px', color: '#333' }}>
                {node.name}
              </div>

            </div>
          </Marker>
        ))}
      </Map>

    </>
  );
}

export default MapComponent;
